// .MuiPopper-root{
//     width: 250px !important;
//     margin-left: auto !important;
//     margin-right: auto !important;
//     left: auto !important;
//     right: auto !important;
// }
.verification-home {
    max-width: 600px;
    // max-width: 375px;
    min-width: 350px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    .country-code-input {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        // justify-content: space-between;
        align-items: center;
        border: 1px solid #E1E1E5;
        border-radius: 12px;
        height: 54px;

        &>.MuiAutocomplete-root{
            max-width: 20%;
            .MuiOutlinedInput-notchedOutline{
                border: none;
            }
        }
        &>.MuiTextField-root{
            max-width: 80%;
            .MuiOutlinedInput-notchedOutline{
                border: none;
            }
        }
        .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
            padding-top: 11px;
            padding-bottom: 11px;
            width: 100%;
        }

        
    }    

    .send-otp-button{
        max-width: 220px;
        width: 100%;
    }

    .expired-content {
        margin-top: 1.5rem;

        img {
            max-width: 100px;
        }

        p {
            font-size: 18px;
            margin-top: 1rem;
            font-weight: 500;
            a{
                text-decoration: none;
            }
        }
    }
    .welcome-text {
        font-size: 28px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.03em;
        text-align: center;
    }

    .verification-user-input{
        margin-top: 2rem;
        .name-input{
            input{
                border: 1px solid #E1E1E5;
                height: 54px;
                border-radius: 12px;
                background: #fff;
                font-size: 16px;
                font-weight: 500;
                line-height: 21.6px;
                letter-spacing: 0.08em;
                text-align: left;
                caret-color: #027BFC; 
                &:focus{
                    box-shadow: none !important;
                    border-color: #E1E1E5;
                }
            }
        }
        .typo-text {
            font-size: 16px;
            font-weight: 600;
            line-height: 22.4px;
            text-align: left;
            margin-top: 1rem;
    
        }
        .phone-input{
            .MuiTextField-root{
                height: 54px;
                border-radius: 12px;
                border: 1px solid #E1E1E5;
                .MuiInputBase-root{
                    height: 54px;
                    border-radius: 12px;
                }
            }
        }
        .phone-input{
          .input-group-text{
            background-color: #fff;
            border-top-left-radius: 12px !important;
            border-bottom-left-radius: 12px !important;
            font-size: 16px;
            font-weight: 500;
            line-height: 21.6px;
            letter-spacing: 0.08em;
            text-align: left;
            padding-right: 0px;
            svg{
                height: 15px;
                margin-left: 5px;
                padding-right: 5px;
                border-right: 1px solid #E1E1E5;
            }
          }
          input{
            background-color: #fff;
            border-top-right-radius: 12px !important;
            border-bottom-right-radius: 12px !important;
            border-left: 0;

            font-size: 16px;
            font-weight: 500;
            line-height: 21.6px;
            letter-spacing: 0.08em;
            text-align: left;
            caret-color: #027BFC; 

            &:focus{
                // border-color: transparent !important;
                // border: none !important;
                box-shadow: none !important;
                border-color: #E1E1E5;
            }
           
          }
          .input-group{
            height: 54px;
            border-radius: 12px;
            &>.form-control:focus{
                box-shadow: none !important;
            }
          }
        }
       
        .MuiTextField-root{
            
            .MuiOutlinedInput-input{
                font-weight: 500;
                font-size: 16px;
                // padding: 14px 14px;
                border: 0px solid #E1E1E5;
                border-radius: 12px;
                
                
                &:focus-visible{
                    outline: none;
                }
            }
        }
       
        label{
            font-weight: 600;
        }
    }

    
   
    .app-name{
        font-size: 44px;
        font-weight: 600;
        line-height: 59px;
        letter-spacing: -0.03em;
        text-align: center;
    }

    .verification{
        margin-top: 2.5rem;
    }

    .verification-card{
        border: none;
        background-color: #fff;
        border-radius: 16px;
        padding: 20px;
        max-width: 600px;
        width: 600px;
        max-height: 100px;        
        margin: auto;
        margin-bottom: 1.5rem;

        section{
            display: flex;
            justify-content: space-between;

            .verification-icon{

            }

            .left{
                display: flex;
                // justify-content: space-between;
                align-items: center;
                text-align: start;
                .type-icon {
                    width: 60px;
                    height: 60px;
                    background-color: #F1F3F6;
                    display: flex;
                    border-radius: 10px;
                    margin-right: 15px;

                    .email{
                        height: 30px;
                        width: 39px;    
                        margin: auto;                 
                    }
                    .mobile{                      
                        height: 38px;
                        width: 22px;
                        margin: auto;
                    }
                   
                }
                .content{
                    h4 {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 0px;
                        text-align: left;    
                        color: #32323B;                
                    }
                    p{
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 22px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #545463;
                        margin-bottom: 5px;
                        &.verified{
                            color: #508F1E;
                            font-weight: 600;
                        }
                    }
                }
            }
            .right{
                display: flex;
                align-items: center;
                .action-icon{
                    width: 42px;
                }
            }
        }

        
    }
    .instructions-section {
        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: center;  color: #32323B;

            &.verified{
                font-weight: 600;
                font-size: 20px;
                color: #447A1A;
            }
        }
    }
}
.payment-section{
    max-width: 90%;
    h5{
        line-height: 1.5;
        font-weight: 600;
        font-size: 18px;
    }     
    p{
        line-height: 1.5;
        font-weight: 500;
        font-size: 15px;
    }   
    .payment-sucess-image{
        max-width: 125px;
        margin-bottom: 1.5rem;
    }

    .invalid-text{
        color: red;
        font-size: 14px;
        text-align: start;
    }
}
.otp-verify-section{
    max-width: 600px;
    .heading{
        font-size: 44px;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: -0.03em;
        text-align: center;
        color: #25252C;
    }
    .verify-icon{
        max-width: 120px;
        margin: 2rem;
        &.mobile{
            width: 120px;
            height: 120px;
            background-position: center;
            background-size: cover;
            margin: 2rem auto;
            background-image: url(../assets/img/icon/otp-mobile.png);
        }
    }
    .sent-message{
        font-size: 20px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
        color: #32323B;
        margin-bottom: 1rem;
    }

    .otp-input-section {
        width: 250px;
        margin: auto;

        &.incorrect {
            &>div {
                input {
                    border: 2px solid #FF6E66;
                    border-color: #FF564D !important;
                }
            }
        }
    
        &>div {
            justify-content: space-between;
    
            input {

                max-width: 100% !important;
                width: 55.06px !important;
                height: 60px !important;
                border-radius: 13px;
                border: 0.6px solid #E1E1E5;
                background: #f9f7f7;
                font-weight: 800;
                font-size: 24px;               

                &:focus-visible {
                    // box-shadow: 0px 1px 10px #57307c9e;
                    // outline: 0px solid #57307c85;
                    box-shadow: none;
                    outline:none;

                }
            }
        }
        .resend-text{
            margin-top: 1rem;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #027BFC;
            width: fit-content;
            margin: 15px auto;

            &.enable{
                color: #027BFC;
                font-weight: 600;
                cursor: pointer;
            }
        }

        .incorrect-otp-text{
            margin-top: 1rem;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #CC0B00;
        }

       
    }
    
}

.verification-footer-section{
    margin-top: 2rem;

    .incorrect-otp-text{
        margin-top: 1rem;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #FF564D;
    }
    .resend-text{
        margin-top: 1rem;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #545463;
        width: fit-content;
        margin: 1.5px auto;

        &.enable{
            color: #027BFC;
            font-weight: 600;
            cursor: pointer;
        }
    }


    .send-otp-button{
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        border-radius: 12px;
        width: 200px;
        width: 100%;
        height: 40px;
        color: #FFFFFF;
        background-color: #1C1C1C;
        border: none;
        font-weight: 500;

        &:disabled{
            background-color: #AFAFBB;
            &:hover{
                cursor: not-allowed;
            }
        }
        
    }

    .verify-otp{
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        border-radius: 12px;
        max-width: 222px;
        font-weight: 600;
        width: 222px;
        height: 40px;
        margin-top: 1.5rem;

        color: #FFFFFF;
        background-color: #1C1C1C;
        border: none;
        font-weight: 500;

        &.btn:disabled{
            color: #FFFFFF;
            background-color: #AFAFBB;
            border: none;
        }
    }
}



.payment-button{
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 12px;
    min-width: 200px;
    height: 40px;

    color: #FFFFFF;
    background-color: #1C1C1C;
    border: none;
    font-weight: 500;
    margin-bottom: 1rem;

    &:hover,
    &:active,
    &:focus-visible{
        color: #FFFFFF !important;
        background-color: #1C1C1C !important;
        border: none !important;
        box-shadow: none !important;
    }
}

.verification-info{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.MuiInputAdornment-root{
    svg{
        padding-right: 5px;
        cursor: pointer;
    }

    b{
        height: auto !important;
        border-left: 2px solid;
        padding-left: 5px;
        color: blue !important;
        cursor: pointer;

        &.disabled-check{
            color:#32323B !important;
        }
    }
   
}

.payment-details-card{
    padding: 10px;
    margin-bottom: 1rem;
    p{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        span{
            text-align: start;
        }
        &:last-child{
            font-weight: 600;
            margin-top: 10px;
            font-size: 16px
        }
    }
}

@media only screen and (max-width: 768px) {
    // .MuiPopper-root{
    //     width: 90% !important;
    //     margin-left: 5% !important;
    //     margin-right: 5% !important;
    // }
    .verification-home{
        max-width: 90%;
        min-width: 90%;
        margin-left: auto;
        margin-right: auto;

        .send-otp-button{
            // width: 100%;
            
            margin-top: 10px;
            border-radius: 12px;
        }

        .typo-text {
            font-size: 16px;
            font-weight: 600;
            line-height: 22.4px;
            text-align: left;
            padding: 0%;
            margin-top: 1rem;
            margin-bottom: 5px;
        }

        .MuiInputBase-formControl{

        }
        .country-code-input {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-content: center;
            // justify-content: space-between;
            align-items: center;
            border: 1px solid #E1E1E5;
            border-radius: 12px;
            &>.MuiAutocomplete-root{
                max-width: 20%;
                .MuiOutlinedInput-notchedOutline{
                    border: none;
                }
            }
            &>.MuiTextField-root{
                max-width: 80%;
                .MuiOutlinedInput-notchedOutline{
                    border: none;
                }
            }
            .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
                padding-top: 11px;
                padding-bottom: 11px;
                width: 100%;
            }

            
        }        

        .MuiTextField-root,
        .MuiOutlinedInput-root{
            &:focus-visible{
                outline: none;
            }
        }

        .MuiTextField-root{
            .MuiOutlinedInput-input{
                font-weight: 500;
                font-size: 16px;
                // padding: 14px 14px;
                border: 1px solid #E1E1E5;
    
                &:focus-visible{
                    outline: none;
                }
            }
        }


        /*  */

        .verification{
            margin-top: 2rem;
        }
        .welcome-text {
            font-size: 16px;
            line-height: 22px;
        }
        .app-name{
            font-size: 24px;
            line-height: 32px;
        }

        .verification-card{
            max-width: 100%;
            width: 100%;
            padding: 10px;

            section{
                .left {    
                    max-width: calc(100% - 40px);
                    // display: ;
                    // grid-template-columns: 40px auto 40px;
                    // gap: 15px; 
                    .content {
                        max-width: calc(92% - 35px);
                        h4 {
                            font-size: 14px;
                            font-weight: 500;
                            margin-bottom: 0px;
                            width: 100%;
                            
                        }            
                        p {
                            font-size: 14px;
                            font-weight: 400;
                        }            
                    }                   

                    .type-icon {
                        width: 48px;
                        height: 48px;
                        margin-right: 10px;
                        .email{
                            height: 20px;
                            width: 30px;    
                            margin: auto;                 
                        }
                        .mobile{                      
                            height: 22px;
                            width: 14px;
                            margin: auto;
                        }
                    }
                }
                .right{
                    min-width: 40px;
                    .action-icon{
                        width: 35px;
                    }
                }
            }
            
        }

        .instructions-section {
            max-width: 80%;
            margin: auto;
            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 27px;
                margin-bottom: 0;
    
                &.verified{
                    font-weight: 600;
                    font-size: 14px;
                }
            }
        }

    }

    .verification-footer-section{
        bottom: 20px;
        padding: 10px 0;
        position: fixed;
        right: 0;
        width: 100%;
    
        .send-otp-button,
        .verify-otp,
        .payment-button{
            width: 90%;
            max-width: none;
            margin-top: 1rem;
        }
        .resend-text{
            margin-bottom: 5px;
            font-size: 14px;
            &.enable{
                color: #027BFC;
                font-weight: 600;
                cursor: pointer;
            }
        }
        .incorrect-otp-text{
            font-size: 12px;
        }
    }

    .otp-verify-section{
        .heading{
            font-size: 24px;
            margin-bottom: 0;
        }
        // .verify-icon{
        //     max-width: 80px;

        // }
        .verify-icon{
            max-width: 80px;
            margin: 2rem;
            &.mobile{
                width: 80px;
                height: 80px;
                background-position: center;
                background-size: cover;
                margin: 2rem auto;
                background-image: url(../assets/img/icon/otp-mobile.png);
            }
        }
        .sent-message{
            font-size: 16px;
            margin: auto;
            margin-bottom: 0.5rem;
            max-width: 90%;
        }

        .otp-input-section {
            margin-top: 1rem;
            width: 220px;

            &>div {        
                input {
                    width: 46px !important;
                    height: 50px !important;
                    font-size: 18px;
                }
            }

            .resend-text{
                font-size: 14px;
            }
    
            .incorrect-otp-text{
                font-size: 12px;
            }

           

        }
    } 
    .verify-otp{
        width: 100%;
    }  
    .payment-button{
        width: 100%;
        margin-top: 10px;
    }

}

.questionnaire-container {
    max-width: 375px;

    .question-icon {
        /* width: 30px; */
        height: 30px;
        margin-bottom: 10px;
    }

    .question-type {
        font-family: 'General Sans' !important;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.12em;
        text-align: center;
        text-transform: uppercase;
        color: #78788C;
    }

}

.question-section {
    margin-bottom: 50px;

    .question-head {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 20px;
    }

    .question-desc {        
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: 0em;
        text-align: center;
        margin: auto;
        margin-top: 10px;
        color: #6C6C7F;
        /* width:90%; */
        max-width: 320px;
    }

    .MuiStack-root{
        margin-top: 50px;
    }
    
  .disclaimer-link {
        margin-top: 10px;
      display: flex;
      justify-content: space-around;
  }

  .disclaimer-link a {
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    color: #1372f5;
    font-weight: 500;
  }
}

.questions-options{
    text-align: left;
    margin-top:2rem;
    font-weight: 500;

    .invalid-text{
        color: #d32f2f;
        font-size: 0.75rem;
        margin-bottom: 5px;    
    }

    label{
        font-weight: 500;
        z-index: 1;
        color: dimgrey;
    }

    .label-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 1.5em;
        padding-right: 1.5em;
        cursor: pointer;
        span {
                &.main {
                    padding: 5px 0px;
                }
                &.sub {
                    /* font-family: "Smitch Sans"; */
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15px;
                    letter-spacing: 0em;
                    text-align: left;
                    color:#606071;
                }
            }
    }

    .form-check-input {
        opacity: 0;
        position: absolute;
        height:20px;
        width: 20px;
        border-radius: 50%;
    }

    .form-check-input[type="checkbox"]  {
        opacity: 1;
        float: right;
        opacity: 1;
        position: relative;
        float: right;
        top: 40%;
        margin-right: 1rem;
        transform: translateY(-50%);
        border: 2px solid #A1A1AF;
    }

    .form-check {
        min-height: 54px;
        /* max-height: 84px; */
        border-radius: 12px;
        box-shadow: 0px 2px 6px 0.5px #00000040;
        margin-bottom: 20px;
        padding-left: 0em;
        padding-top: 5px;
        padding-bottom: 5px;
        border: 2px solid #ffffff00
    }

    .form-check.single-line {
        height: 54px;
    }

    .form-check.double-line {
        .form-check-input[type="checkbox"] {
            transform: translateY(40%);
        }
    }

    .form-check {
        &.selected {
            border: 2px solid #027BFC;
        }
    }

    .form-check-input[type="checkbox"]:checked{
        background-color: #027BFC;
        border-color: #027BFC;
    }

    .time-picker-input{
        appearance: none;
        padding: 1rem;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        width: 100%;
        background: white;
        text-align: left !important;
        color: #1c1c1c;
        letter-spacing: 2px;
        display: flex;
        text-transform: capitalize;
    }
}

.questionnaire-header {
    position: fixed;
    padding: 2rem 1.8rem;
    padding-bottom: 0rem;
    background: #f1f3f6;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;

    &>div {
        width: 100%;
    }

    .progress {
        width: 100%;
        height: 5px;
        .progress-bar{
            background-color: #027BFC;
        }
    }
}

.questionnaire-footer {
    position: fixed;
    background: #f1f3f6;
    width: 100%;    
    bottom: 0px;        
    padding: 10px 0 20px 0px;
    max-width: 375px;
    right: 0;
    left: 0;
    right: 0;
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    .button-section {
        display: flex;
        justify-content: space-between;
        padding: 0 1.8rem;


        .back-question,
        .next-question {
            width: 140px;
            height: 48px;
            padding: 13px 0px 13px 0px;
            border-radius: 10px;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
        }

        .next-question {
            background: #1C1C1C;
            border-color: #1C1C1C;
            color: #fff;

            &.fullview{
                width: 99%;
                max-width: none !important;
            }
        }

        .back-question {
            background: #f1f3f6;
            border-color: #1C1C1C;
            color: #1C1C1C;
        }

        .back-question:active,
        .back-question:focus,
        .back-question:hover {
            background: #f1f3f6  !important;
            border-color: #1C1C1C  !important;
            color: #1C1C1C !important;
        }

        .back-question:disabled,
        .next-question:disabled {
            border-color: #AFAFBB !important;
            background-color: #AFAFBB !important;
            color: #FFFFFF !important;
            opacity: 1;
            max-width: 150px;
        }

        .all-set-button {
            background: #1C1C1C;
            color: #fff;
            width: 100px;
            height: 50px;
            padding: 13px 0px 13px 0px;
            border-radius: 30px;
            font-size: 16px;
            margin: auto;
        }
    }
}


.result-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .result-page-content {
        max-width: 90%;

        h4{
            margin-bottom: 20px;
        }

        p{
            font-size: 16px;
            font-weight: 500;
            line-height: 1.8;
        }
    }
}



@media only screen and (max-width: 768px) {

    .questionnaire-container{
        max-width: 100%;
    }
    .questionnaire-header {
        max-width: 375px;

        .progress {
            height: 5px;
        }
    }

    
.questionnaire-footer {

    .button-section{
        // justify-content: space-evenly;
    }
}
}
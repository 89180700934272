body {
  margin: 0;
  font-family: 'General Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#F1F3F6;
}

.font-protest {
  font-family: "Protest Riot", sans-serif;
}

.MuiAutocomplete-option,
.MuiOutlinedInput-input,
.Toastify__toast{
  font-family: 'General Sans' !important;
}
